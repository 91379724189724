import React from "react";
import * as styles from '../assets/Newannouncement.module.css'
import { Card ,Container} from "react-bootstrap";
import Layout from '../components/layout'
import { graphql ,Link} from "gatsby"
import {HiOutlineSpeakerphone} from '@react-icons/all-files/hi/HiOutlineSpeakerphone'
import Img from 'gatsby-image'

const Announcement = ({data}) =>{

    const event = data.allStrapiNewAnnouncements.nodes[0].NewAnnouncementComponent
    console.log(event)

    let newannouncement = event.map((element,index)=>{
        let getstartdate = new Date(element.StartDateAndTime)

        let startmonth = getstartdate.toLocaleString('default', { month: 'long' })
        let startdate = getstartdate.getDate() <=9 ? '0' + getstartdate.getDate() : getstartdate.getDate()
        let startyear = getstartdate.getFullYear()

        let getenddate = new Date(element.EndDateAndTime)

     
        return(
            <Card className={styles.card}> 
                <div className="row">
                    <div className="col-2" id={styles.startdate}>
                        <div  style={{border:"1px solid black",textAlign:"center"}}>
                            {element.Thumbnail !=null ? <Img fixed={element.Thumbnail.childImageSharp.fixed} />: 
                        <HiOutlineSpeakerphone className={styles.icon}/>}
                        </div>
                    </div> 
                    <div className="col">
                        <div className={styles.rightdata}>
                            <p className={styles.p}>{element.link}</p>
                            <p>{element.shortDescription}</p>
                            <Link to={`/${element.link}`} className={element.PageContent != null ? styles.readmore : styles.disablereadmore} state={{index: index}} >Read more</Link><br/>
                            {/* <div className={styles.time}>
                                <span>Start Date : {getstartdate.toUTCString()}</span> &nbsp; &nbsp;
                                {element.EndDateAndTime == null ? '':
                                <span>End Date : {getenddate.toUTCString()}</span>}
                            </div> */}
                        </div>
                    </div>
                </div>
            </Card>
        )
    })
     
    return(
        <Layout>
        <Container>
            <h5 style={{textAlign:"center",fontWeight:"600",fontSize:"30px",marginTop:"20px"}}>{data.allStrapiNewAnnouncements.nodes[0].Title}</h5>
            <div className="row">
                {newannouncement}
            </div>
        </Container>
        </Layout>
       )
}

export const query = graphql`
  {
    allStrapiNewAnnouncements(sort: {fields: NewAnnouncementComponent___StartDateAndTime, order: ASC}) {
        nodes {
          NewAnnouncementComponent {
            link
            StartDateAndTime
            EndDateAndTime
            PageContent
            Thumbnail {
                childImageSharp {
                  fixed(height: 130) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            shortDescription
          }
          Title
        }
      }
  }
`

export default Announcement